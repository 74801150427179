import React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import { Card, ListGroup, Row, Col, Container } from "react-bootstrap"

const getData = graphql`
  {
    allContentfulPoem {
      nodes {
        title
        id
        slug
      }
    }
  }
`

const PoetryHeading = () => {
  const data1 = useStaticQuery(getData)
  const titles = data1.allContentfulPoem.nodes

  return (
    <Card style={{ border: "none", backgroundColor: "transparent" }}>
      <Container >
        <ListGroup variant="flush">
          <Row>
            {titles.map(title => {
              return (
                <Col
                  xs={{ span: 12 }}
                  sm={{ span: 12 }}
                  md={{ span: 6 }}
                  lg={{ span: 6 }}
                  xl={{ span: 6 }}
                  key={title.id}
                  style={{
                    paddingLeft: "0px"
                  }}
              >
                  <Link
                    to={`/poetry/${title.slug}`}
                    className="link-no-style"
                    style={{
                      textDecoration: "none",
                      color: "black",
                      paddingLeft: "0px",
                    }}
                  >
                    <h5>
                      <ListGroup.Item
                        action
                        style={{
                          border: "none",
                          backgroundColor: "transparent",
                          paddingLeft: "0px",
                          paddingRight: "0px",
                        }}
                      >
                        {title.title}
                      </ListGroup.Item>
                    </h5>
                  </Link>
                </Col>
              )
            })}
          </Row>
        </ListGroup>
      </Container>
    </Card>
  )
}

export default PoetryHeading
