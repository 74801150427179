import React from "react"
import { Card } from "react-bootstrap"


const Poem = ({ paragraph }) => {
  return (
    <>
      <Card.Text style={{ marginBottom: "0px" }} className="anti-copy">
        {paragraph.split("\n").map((line, index) => {
          return (
            <span id="paragraph" key={index}>
              {" "}
              {line}{" "}
            </span>
          )
        })}
      </Card.Text>
    </>
  )
}

export default Poem
