import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import PoetryHeading from "../components/PoetryHeading"
import { Card } from "react-bootstrap"
import Paragraph from "../components/Poem"
const ComponentName = ({ data }) => {
  return (
    <Layout>
      <h2>Poetry</h2>
      <PoetryHeading />

      <Card
        style={{ border: "none", backgroundColor: "transparent" }}
        className="anti-copy"
      >
        <Card.Body
          style={{ 
            paddingLeft: "0px",
            paddingRight: "0px",
            marginBottom: "25px",
          }}
        >
          <Card.Title>{data.contentfulPoem.title}</Card.Title>

          {data.contentfulPoem.data.data.split("$").map((paragraph, index) => {
            return <Paragraph paragraph={paragraph} key={index} />
          })}
        </Card.Body>
      </Card>
    </Layout>
  )
}

export const query = graphql`
  query GetPoem($slug: String) {
    contentfulPoem(slug: { eq: $slug }) {
      data {
        data
      }
      title
    }
  }
`

export default ComponentName
